<template>
  <div class="beta-program-accepted">
    <div>
      <h1 class="beta-program-accepted__title">
        Woo hoo! You're in!
      </h1>
      <h2 class="beta-program-accepted__instructions">
        Now follow the steps below to access the Beta program.
      </h2>
      <div class="beta-program-accepted__section">
        <div class="beta-program-accepted__tutorial">
          <h3 class="beta-program-accepted__subtitle">
            Voicemod <span>closed</span>?
          </h3>
          <ul class="beta-program-accepted__list">
            <li>Launch the application</li>
          </ul>
          <h3 class="beta-program-accepted__subtitle">
            Voicemod <span>open</span>? Fully relaunch it
          </h3>
          <ul class="beta-program-accepted__list">
            <li>Go to your system tray</li>
            <li>Right click on the Voicemod icon, select “Exit”</li>
            <li>Re-launch Voicemod</li>
          </ul>
          <img
            class="beta-program-accepted__gif"
            src="../../assets/images/exit-from-systray.gif"
            alt="Exit from systray"
          >
          <h3 class="beta-program-accepted__subtitle">
            <span>Not a Voicemod user</span> yet?
          </h3>
          <ul class="beta-program-accepted__list">
            <li>Download Voicemod <a :href="downloadUrlWithUTM">here</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOGIN_ACTIONS_CONSTANTS } from '@/domain/login';
import { ROUTER_PATHS } from '@/router/paths';
import { URL_CONSTANTS } from '@/externalURLs';
import { CookieService } from '@/services/cookie';
import { AccountClient } from '@/services/models/accounts';
import { userDomain } from '@/domain/user';

const downloadUrlWithUTM =
    `${URL_CONSTANTS.HOMEPAGE}?utm_source=vm-accounts&utm_medium=beta-program&utm_campaign=thank-you-download-link`;

export default {
    data() {
        return {
            downloadUrlWithUTM,
        };
    },
    computed: {
        hasValidSession() {
            return this.userId;
        },
        userId() {
            return CookieService.getCookie('userId');
        },
    },
    async beforeMount() {
        if (!this.hasValidSession) {
            this.redirectToLogin();
        }
    },
    async mounted() {
        const userConsent = userDomain.generateConsent({
            betaProgram: true,
        });
        await AccountClient.updateUserConsents(this.userId, userConsent);
    },
    methods: {
        redirectToLogin() {
            this.$router.push({
                path: ROUTER_PATHS.HOME,
                query: {
                    action: LOGIN_ACTIONS_CONSTANTS.BETAPROGRAM,
                },
            });
        },
    },
};
</script>

<style src="./NewBetaProgramAcceptedView.scss" lang="scss" />
